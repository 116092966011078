import { createEffect, createSignal } from "solid-js";
import { TOCPageProps } from "../../model";
import { Atom } from ":mods";
import { ROUTES } from "../../const";

export function TOC({ title, terms, agreements }: TOCPageProps) {
  const [termsAccepted, setTermsAccepted] = createSignal([]);
  const [enableButton, setEnableButton] = createSignal(false, { equals: false });

  createEffect(() => {
    if (
      termsAccepted().length > 0 &&
      termsAccepted().every((t) => t?.agreed) &&
      termsAccepted().length == agreements.length
    ) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [termsAccepted]);
  const handleAgreements = ({ currentTarget }) => {
    const id = currentTarget?.value;
    const currentTerm = termsAccepted()?.find((term: any) => term?.id == id);
    const obj = { id: id, agreed: currentTerm?.agreed ? false : true };
    if (!currentTerm) {
      setTermsAccepted([...termsAccepted(), obj]);
    } else {
      setTermsAccepted([...termsAccepted().filter((term: any) => term.id != currentTerm?.id), obj]);
    }
  };
  return (
    <main class="flex flex-col items-center justify-between h-80vh w-screen">
      <section class="flex flex-col gap-40px w-628px pt-80px">
        <h1>{title}</h1>
        {terms?.map((term) => {
          return (
            <article>
              <h1 class="font-bold">{term.title}</h1>
              <p class="text-16px">{term.description}</p>
            </article>
          );
        })}
        {agreements?.map((agreement) => {
          return (
            <div class="flex gap-13px text-16px">
              <input
                class="w-23px h-23px cursor-pointer"
                type="checkbox"
                value={agreement.id}
                checked={agreement.checked}
                onClick={handleAgreements}
              />
              <p>{agreement.text}</p>
            </div>
          );
        })}
        <div class="flex justify-end">
          <Atom.Buttons.SubmitButton link={".." + ROUTES.COMMITMENT} disabled={!enableButton()}>
            {"Next"}{" "}
          </Atom.Buttons.SubmitButton>
        </div>
      </section>
    </main>
  );
}
